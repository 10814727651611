body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  position: relative;
}
body:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}
body.offcanvas-menu:before {
  opacity: 1;
  z-index: 1002;
  visibility: visible;
}

p {
  color: #9b7218;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Staatliches", sans-serif;
  color: #55443f;
}
a {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
a,
a:hover {
  text-decoration: none !important;
}
button {
  border: 0;
}
button:focus {
  outline: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-link {
  padding-right: 10% !important;
  font-family: "Staatliches", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 1px;
  color: #55443f;
}
.breadcrumb {
  background-color: transparent;
  font-size: 14px;
  margin-bottom: 0;
}
.breadcrumb .breadcrumb-item a {
  color: #9b7218;
  font-weight: 400;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="time"],
input[type="date"] {
  position: relative;
}
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
}
.input-has-err {
  border-color: red !important;
}
.form-text {
  font-size: 12px;
  color: red;
}
.toast-success {
  background-color: rgb(122, 189, 145, 0.95);
}
.toast-error {
  background-color: rgb(255, 105, 98, 0.95);
}

.category-banner {
  background: url(/assets/images/shared/page-title-banner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 0;
}

.owl-carousel .owl-item img {
  border-radius: 10px;
}
.owl-nav > * {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38);
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumb-row .owl-nav > * {
  background: transparent;
}
.owl-nav .owl-prev {
  left: 0;
}
.owl-nav .owl-next {
  right: 0;
}
.owl-nav span {
  font-size: 50px;
  color: #6d453e;
  margin-bottom: 5%;
}
.thumb-row .owl-nav span {
  color: #fff;
}
.breadcrumb-item.active {
  color: #55443f;
}
.form-control, .form-control:focus{
  color: #9b7218;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: #55443f;
}
textarea {
  resize: none;
}
.modal-body {
    padding: 0rem 1rem 1rem;
}
@media only screen and (min-width: 300px) and (max-width: 768px) {
  /* html,
  body {
    overflow-x: hidden;
  } */
  .h5, h5 {
    font-size: 1rem;
}
h1{
margin-bottom: 0;
}
.h1, h1 {
    font-size: calc(1.2rem + 1.5vw);
}
.h4, h4 {
    font-size: calc(1.2rem + .3vw);
}
.page-title-section {
    background-size: auto!important;
}
}
